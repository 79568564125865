<template>
  <div class="api-welcome">

    <api-blue-header>
      <template #title>
        {{ $t('welcome.header') }}
      </template>

      <div v-html="$t('welcome.description')"></div>
    </api-blue-header>

    <div class="hds-container api-entry">
      <h2 class="hds-h2">{{ $t('welcome.siem') }}</h2>
      <div class="hds-container-narrow-large" v-html="$t('welcome.siem_text')"></div>
      <router-link to="/getting-started"></router-link>

      <h2 class="hds-h2">{{ $t('welcome.api') }}</h2>
      <p v-html="$t('welcome.api_text')"></p>

      <div class="hds-row">
        <div class="hds-col-6">
          <h3>
            <span class="hds-products hds-products-fusion hds-icons-products">
                <hds-svg-icon icon="logo" size="large" color="interactive"/>
            </span>{{ $t('common.elements_api') }}
          </h3>
          <p>
            {{ $t('welcome.elements_text') }}
          </p>
          <p>
          <router-link class="hds-link hds-margin" :to="{ name: 'gs-elements' }">
              {{ $t('common.getting_started') }}
              <hds-svg-icon class="icon-arrow-right" icon="arrow-right" color="interactive" size="small" />
          </router-link>
        </p>
        <p>
          <router-link class="hds-link" :to="{ name: 'api-elements' }">
              {{ $t('common.api_ref') }}
              <hds-svg-icon class="icon-arrow-right" icon="arrow-right" color="interactive" size="small" />
          </router-link>
        </p><p>
          <router-link class="hds-link" :to="{ name: 'gs-cookbook' }">
              Cookbook
              <hds-svg-icon class="icon-arrow-right" icon="arrow-right" color="interactive" size="small" />
          </router-link>
        </p>
        </div>
        <div class="hds-col-6">
            <h3>
                <span class="hds-products hds-products-fusion hds-icons-products">
                    <hds-svg-icon icon="vm-large" size="x-large"/>
                </span>{{ $t('common.radar_api') }}
            </h3>
            <p>
            {{ $t('welcome.radar_text') }}
            </p>
            <a class="hds-link hds-margin-base-bottom" target="_blank" href="https://www.withsecure.com/userguides/product.html#business/radar/4.0/en/concept_35AD9E6374114D41B56275182796EE1A-4.0-en">
                {{ $t('common.getting_started') }}
            </a>
            <br>
            <a class="hds-link" target="_blank" href="https://api.radar.f-secure.com/apidoc/integration/">
                {{ $t('common.api_ref') }}
            </a>
        </div>
        <div class="hds-col-6">
          <h3>
            <span class="hds-products hds-products-fusion hds-icons-products">
                <hds-svg-icon icon="ep-large" size="x-large"/>
            </span>{{ $t('common.prov_api') }}
          </h3>
          <p>
            {{ $t('welcome.provisioning_text') }}
          </p>
          <router-link class="hds-link hds-margin-base-bottom" :to="{ name: 'gs-provision' }">
              {{ $t('common.getting_started') }}
              <hds-svg-icon class="icon-arrow-right" icon="arrow-right" color="interactive" size="small" />
          </router-link>
          <br>
          <router-link class="hds-link" :to="{ name: 'api-provision' }">
              {{ $t('common.api_ref') }}
              <hds-svg-icon class="icon-arrow-right" icon="arrow-right" color="interactive" size="small" />
          </router-link>
        </div>
      </div>
      <p>
        {{ $t('welcome.api_text2') }}
      </p>
    </div>
  </div>
</template>

<script>
import BlueHeader from "@/components/BlueHeader";
import { mapGetters } from 'vuex'

export default {
  components: {
    'api-blue-header': BlueHeader,
  },
  computed: {
    ...mapGetters(['isLocalOrCiEnvironment', 'notProduction'])
  }
}
</script>

<style scoped>
  h2 {
    color: #0014a0;
    margin: 40px 0 20px;
    font-size: 24px;
    font-weight: 700;
  }
  h3 {
    position: relative;
    font-weight: 700;
    padding-left: 60px;
    max-width: 440px;
    min-height: 45px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
  }

  .hds-icons-products {
    position: absolute;
    top: 0;
    left: 0;
  }
  .api-entry .hds-col-6 p {
    max-width: 480px;
    margin-bottom: 10px;
  }
  .api-entry .hds-col-6 {
    margin-bottom: 40px;
  }
  .icon-arrow-right {
    position: relative;
    top: -1px;
  }
  a:hover .icon-arrow-right {
    color: #05a;
  }
  .hds-link {
      display: inline-block;
  }
</style>
